.projectsContainer {
    padding: 4em;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding: 3em 1em;
    }

    .projectCardContainer {
        max-width: 70em;
        margin-bottom: 4em;
    }
}
