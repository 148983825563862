.companyContainer {
    padding-bottom: 4em;

    .title {
        span {
            font-size: 28px;
            font-weight: 400;
        }
    }
    .role {
        span {
            font-size: 24px;
            font-weight: 300;
        }
    }
    .years {
        span {
            font-size: 20px;
            font-weight: 200;
        }
    }
}
