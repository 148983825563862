.aboutContainer {
    padding: 2em;
    display: flex;
    justify-content: center;

    .informationContainer {
        max-width: 60em;
        direction: ltr;

        .buttonContainer {
            padding: 2em;
            justify-content: center;
        }
    }
}
