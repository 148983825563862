@import '../../styles/colors.scss';
.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
    gap: 3em;
    position: sticky;
    top: 0;
    background-color: $white; /* Add background color if needed */
    z-index: 1; /* Set an appropriate z-index to layer it properly */

    @media screen and (max-width: 768px) {
        padding: 2em 0 1em;
        gap: 20px;
    }

    .headerOptionContainer {
        padding: 1em 3em;
        border-radius: 50px;
        transition: transform 0.3s ease; /* Add a transition for the transform property */

        @media screen and (max-width: 768px) {
            padding: 8px;
        }

        span {
            color: $black;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
    }

    .headerOptionContainer:hover {
        animation: shakeLeftRight 0.5s ease-in-out 1; /* Use the shakeLeftRight animation on hover */
    }

    .languagesIconContainer {
        display: flex;

        .languagesIcon {
            cursor: pointer;
        }
    }

    @keyframes shakeLeftRight {
        0%,
        100% {
            transform: translateX(0);
        }
        25%,
        75% {
            transform: translateX(-5px); /* Adjust the distance to shake to the left */
        }
        50% {
            transform: translateX(5px); /* Adjust the distance to shake to the right */
        }
    }

    .activeHeaderOption {
        border: 1px solid $soft-gray;
        cursor: pointer;
    }
}

.selectLanguageModalContainer {
    .language {
        cursor: pointer;
        padding: 2em 6em;
        justify-content: center;

        span {
            font-size: 16px;
        }
    }

    .language:hover {
        background-color: $black;
        color: $white;
    }
}
