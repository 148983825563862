@import '../../styles/colors.scss';

.footerContainer {
    background-color: $black;

    .footerRow {
        padding: 4em;

        @media screen and (max-width: 768px) {
            padding: 4em 1em;
        }
        .footerColumn {
            .footerRowInformation {
                justify-content: center;
                padding: 5px;
            }

            .footerQuoteRowInformation {
                justify-content: left;
                font-family: 'Dancing Script', cursive;
                padding: 5px;

                @media screen and (max-width: 768px) {
                    padding: 1em 0;
                }

                .author {
                    font-size: 20px;
                }

                span {
                    font-size: 28px;
                }
            }

            .socialIconsContainer {
                gap: 1em;
                padding: 2em 0;

                @media screen and (max-width: 768px) {
                    padding: 3em 0;
                }

                .socialIcon {
                    cursor: pointer;
                    filter: invert(1) grayscale(100%) brightness(1000%);
                    width: 2em;
                    height: 2em;
                }
                .socialIcon:hover {
                    width: 36px;
                    height: 36px;
                }
            }

            span {
                color: $white;
                font-size: 22px;
                font-weight: 200;
            }
        }
    }
}

.he {
    justify-content: center !important;
    direction: ltr;
}
