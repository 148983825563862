.notFoundContainer {
    padding: 14em 0;
    .mainRow {
        justify-content: center;

        span {
            font-size: 60px;
            font-weight: 200;

            @media screen and (max-width: 768px) {
                font-size: 35px;
                font-weight: 200;
            }
        }
    }
}
