.contactMeContainer {
    padding: 3em 5em;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding: 2em 2em 7em 2em;
    }

    .inputContainer {
        justify-content: center;
        padding: 1em 0;
    }

    .lettersCounter {
        justify-content: center;
    }

    .recaptcha {
        justify-content: center;
    }

    .submitButtonContainer {
        justify-content: center;
        padding: 2em 0;
    }
}
