@import './colors.scss';

.defaultButton {
    border: 1px solid $soft-gray;
    background-color: $white;
    color: $black;
    font-size: 14px;
    padding: 1em 2em;
    border-radius: 50px;
    cursor: pointer;
    outline: none;
}

.projectCardBlack {
    background-color: $black;
    direction: rtl;
    text-align: left;
}

.projectCardSoftGray {
    background-color: $soft-gray;
}
