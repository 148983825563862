@import '../../styles/colors.scss';
.projectCardContainer {
    border-radius: 40px;

    .columnContainer {
        padding: 4em 4em 0 4em;

        @media screen and (max-width: 768px) {
            padding: 2em 2em 0 2em;
        }

        .image {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        .zoomIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            font-size: 40px;
            box-shadow: 0 20px 30px rgba(0, 0, 0, 0.7); /* Add shadow */
            border-radius: 10px;
        }

        .title {
            color: $white;
            justify-content: left;
            span {
                font-size: 24px;
                font-weight: 300;
            }
        }
        .tech {
            justify-content: left;
            direction: ltr;
            padding: 1em 0;
            color: $white;
            gap: 5px;
            span {
                font-size: 18px;
                font-weight: 200;
            }
        }
        .description {
            color: $white;
            padding: 2em 0 0;
            justify-content: left;
            span {
                font-size: 16px;
                font-weight: 100;
            }
        }
        .git {
            justify-content: left;
            color: $white;
            padding: 2em 0;
            span {
                font-size: 18px;
                font-weight: 200;
                align-items: center;
                display: flex;
                gap: 10px;
                cursor: pointer;
            }
        }
    }
}
