.contactsContainer {
    .titleContainer {
        padding: 3em 1em;
        justify-content: center;
        text-align: center;

        span {
            font-size: 25px;
            font-weight: 600;
        }
    }
}
