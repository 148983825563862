.textInputContainer {
    width: 30em;

    @media screen and (max-width: 768px) {
        width: 20em;
    }

    .textInputLabel {
        font-size: 14px;
        font-weight: 600;
    }

    .he {
        text-align: right;
    }
}
