.iModalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 65em;
    background-color: white;
    border-radius: 1em;
    padding: 1em;

    @media screen and (max-width: 768px) {
        width: 95%;
    }
}
