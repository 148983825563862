body {
    margin: 0;
    font-family: 'Sofia Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.row {
    margin: 0;
}
