.carouselContainer {
    overflow: hidden;
    position: relative;
    height: 100px;

    .carouselTrack {
        display: flex;
        position: absolute;
        left: 0;
        justify-content: center;
        align-items: center;
        height: 80px;
        gap: 100px;
        width: 200%;
        height: 100%;
        animation: slide 70s linear infinite;

        @media screen and (max-width: 1000px) {
            gap: 20px;
            animation: slide 20s linear infinite;
            width: 400%;
        }

        @keyframes slide {
            0% {
                left: 0;
            }
            100% {
                left: -100%;
            }
        }

        .carouselCard {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 90px;

            .icon {
                width: 100%;
                height: 100%;
            }
        }
    }
}
