.myJourneyContainer {
    padding: 2em;
    background: linear-gradient(to bottom left, #e9e7e7, #eef4f7);

    .myJourneyMainRowContainer {
        .column {
            padding: 2em;
        }

        .titleColumn {
            @media screen and (max-width: 768px) {
                justify-content: center;
                display: flex;
            }
            span {
                font-size: 50px;
                font-weight: 100;
            }
        }

        .companiesColumn {
            justify-content: center;
            display: flex;

            .companiesContainer {
                width: 100%;
            }
        }
    }
}

.he {
    text-align: center;
}
