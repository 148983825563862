.contactContainer {
    padding: 12em 0;

    .textContainer {
        justify-content: center;
        text-align: center;

        span {
            font-size: 130px;
            font-weight: 100;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                font-size: 50px;
            }
        }
        span:hover {
            animation: shakeLeftRight 0.5s ease-in-out 1; /* Use the shakeLeftRight animation on hover */
        }
    }
}

@keyframes shakeLeftRight {
    0%,
    100% {
        transform: translateX(0);
    }
    25%,
    75% {
        transform: translateX(-10px); /* Adjust the distance to shake to the left */
    }
    50% {
        transform: translateX(10px); /* Adjust the distance to shake to the right */
    }
}
