@import '../../../styles/colors.scss';

.quickAboutContainer {
    display: flex;
    justify-content: center;
    padding: 2em 2em 0 2em;

    @media screen and (max-width: 768px) {
        padding: 0;
    }

    .informationRow {
        margin-top: 5em;
        max-width: 95em;

        .profileImageContainer {
            display: flex;
            justify-content: center;
            align-items: flex-end;

            .profileImage {
                width: 100%;
                max-width: 25em;
            }
        }

        .descriptionsContainer {
            @media screen and (max-width: 768px) {
                padding: 0 1em;
            }

            @keyframes fadeIn {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }

            .animation {
                animation: fadeIn 10s ease;
                color: $black;
            }

            .title {
                text-align: center;

                span {
                    font-size: 70px;
                    font-weight: 600;

                    @media screen and (max-width: 768px) {
                        font-size: 50px;
                    }
                }
            }

            .role {
                text-align: center;

                span {
                    font-size: 50px;
                    font-weight: 400;

                    @media screen and (max-width: 768px) {
                        font-size: 30px;
                    }
                }
            }

            .description {
                text-align: center;
                padding: 2em 0;

                span {
                    font-size: 22px;
                    color: $soft-gray;
                }
            }
        }
    }
}
